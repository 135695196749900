import { isShowPickUpInfo } from "./isShowPickUpInfo";
import { segmentIds } from "screens/controlPanel/addCouponType/store/logic/segmentIds";

export function getPurchasesUsersInfoTableData(store, segmentId) {
    const { segmentCouponPurchasersInfo } = store;
    const tableDataArray = segmentCouponPurchasersInfo
        ? segmentCouponPurchasersInfo.map(item => {
              const array = [item.couponTitle, item.userIdentifier, item.phone, item.userName, item.date];
              if (segmentId == segmentIds.OVDIM_BARI_SEGMENT_ID) {
                  array.push(item.isUsed ? "כן" : "לא");
                  array.push(item.costToOvdim ? `${item.costToOvdim}₪` : "-");
                  array.push(item.coins ? item.coins : "-");
              }
              if (item.segmentName) {
                  array.push(item.segmentName);
              }

              if (item.couponCode) {
                  array.splice(1, 0, item.couponCode);
              }

              if (isShowPickUpInfo(segmentId)) {
                  array.splice(1, 0, item.pickUpPoint);
              }

              return array;
          })
        : [];
    return tableDataArray;
}
